import * as React from "react"
import Layout from '../components/Layout';

const CampusPage = () => {
  return (
    <Layout pageTitle="Our Campus">
      <h1>Hi</h1>
    </Layout>
  )
}

export default CampusPage
